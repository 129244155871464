.Home_main__EtNt2 {
  min-height: 100vh;
  padding: 15px;
}

.Home_header__qwdnY {
  width: 100%;
  padding: 50px 0;
}

.Home_container__Ennsq {
  width: 920px;
  margin: auto;
}

.Home_menu__dA7XX {
  list-style-type: none;
}

.Home_pullLeft__YFntN {
  float: left;
}

.Home_menu__dA7XX li {
  float: left;
}

.Home_routeLink__RvcBe {
  display: block;
  padding: 15px 15px;
  text-decoration: none;
  font-weight: bold;
  border-bottom: none;
  transition: border-bottom 0.3s ease-in;
}

.Home_routeLink__RvcBe.Home_active__IcXgb {
  border-bottom: 1px solid rgb(221, 221, 221);
}

.Home_routeLink__RvcBe:hover {
  opacity: 0.6;
  border-bottom: 1px solid rgb(221, 221, 221, 0.6);
}

.Home_headerTools__2QgK2 {
  padding: 15px;
  display: flex;
  gap: 0.5rem;
}

.Home_pullRight__cikdB {
  float: right;
}

.Home_socialWrapper__kHYN1 {
  display: block;
  background-color: #0a3477;
  cursor: pointer;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  margin-left: 3px;
}

.Home_linkedin__rWWg6:hover {
  background-color: #0170ad;
}

.Home_instagram__Pik5s:hover {
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
}

.Home_facebook__Jnsam:hover {
  background-color: #3b5998;
}

.Home_twitter__nyKHZ:hover {
  background-color: #1da1f2;
}

.Home_github__15B_P:hover {
  background-color: #000;
}

.Home_fixedWrapper__m5jTa {
  width: 100%;
}

.Home_clearfix__tU_Vz::after {
  content: "";
  clear: both;
  display: table;
}

.Home_homepageTitle__gQ1pk {
  width: 100%;
  position: relative;
  padding: 100px 0 0 0;
}

.Home_profileImage__uGlM_ {
  position: absolute;
  top: 0;
  right: -20%;
  z-index: 400;
  overflow: hidden; /* Ensure that the drop shadow doesn't overflow */
  border-radius: 10%; /* Make the container a circle */
}

.Home_mainImage__p1utu {
  max-width: 100%;
  width: auto; /* Ensure the image fits within the circle */
  height: auto; /* Ensure aspect ratio is maintained */
  /* transition: transform 0.3s ease; */
}

.Home_profileName__auQ6_ {
  font-family: "Kanit", sans-serif;
  font-weight: normal;
  font-size: 10rem;
  line-height: 116px;
}

.Home_profileName__auQ6_ span {
  display: block;
  /* font-size: 8rem; */
  color: rgba(252, 252, 252, 0.4);
}

.Home_profileDesc__DTY_P {
  padding: 20px 0;
  font-size: 30px;
  font-family: "Lato", sans-serif;
  margin-top: 10px;
}

@keyframes Home_showName__gAxUO {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Home_triangle__xTH1a {
  position: absolute;
  display: block;
  float: right;
  right: 0;
  background: radial-gradient(
    100% 100% at 50% 50%,
    rgba(173, 84, 228, 0.29) 2.55%,
    rgba(173, 84, 228, 0.29) 17.07%,
    rgba(136, 66, 183, 0.29) 24.96%,
    rgba(100, 49, 139, 0.29) 34%,
    rgba(69, 34, 101, 0.241) 43.45%,
    rgba(44, 21, 69, 0.128) 53.21%,
    rgba(25, 12, 45, 0.058) 63.36%,
    rgba(11, 5, 28, 0.02) 70.06%,
    rgba(7, 3, 23, 0.034) 75.69%,
    rgba(3, 1, 18, 0.017) 80.7%,
    rgba(0, 0, 15, 0) 100%
  );
}

.Home_toLeft__e6cUK {
  top: 270px;
  width: 29.2825768668%;
  height: 100px;
  border-right: solid 650px rgba(252, 252, 252, 0.295);
  border-bottom: solid 300px transparent;
  border-top: solid 300px transparent;
}

.Home_toRight__8Vo8f {
  width: 29.2825768668%;
  height: 100px;
  border-left: solid 650px var(--background-rgb);
  border-bottom: solid 0px transparent;
  border-top: solid 300px transparent;
  z-index: 600;
  top: 570px;
}

.Home_introBlock__CyK9G {
  width: 90%;
  margin: 0 auto;
  background: transparent;
  position: relative;
  z-index: 700;
}
.Home_introParagraph__Wut0s {
  text-align: center;
  padding: 50px 30px 50px 30px;
  animation: Home_showName__gAxUO 0.5s;
}

.Home_introHead__9Zu5Y {
  font-size: 20px;
  font-weight: 700;
  margin: 8px 0;
}

.Home_introDesc__jD75g {
  font-size: 16px;
  font-weight: 600;
  margin: 8px 0;
}

.Home_introFooter__vPvOL {
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0;
}

.Home_coloredText__8GhYZ {
  margin: 0 8px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.Home_coloredText__8GhYZ svg {
  margin-left: 8px;
  color: white;
}
.Home_codeText__XTHHk {
  background-image: linear-gradient(to right, #38bdf8, #2563eb);
}

.Home_dumbellText__Jf_St {
  background-image: linear-gradient(to right, #c084fc, #db2777);
}

.Home_travelText__ARZCm {
  background-image: linear-gradient(to right, #84fcab, #2777db);
}

.Home_paraSeparator__aQWC5 {
  font-size: 8px;
  font-weight: 800;
  color: #fff;
  margin: 8px;
}

.Home_hr__tNQHF {
  margin: 20px auto;
  border: 1px solid #d4d5d6;
  opacity: 0.3;
}

@media (prefers-color-scheme: dark) {
  .Home_vercelLogo__lhIxO {
    filter: invert(1);
  }

  .Home_logo__80mSr {
    filter: invert(1) drop-shadow(0 0 0.3rem #ffffff70);
  }
}

.Home_projectIcons__m3zrx {
  color: rgba(252, 252, 252, 0.295);
}

.Home_projectsWrapper__48SCW {
  margin-bottom: 36px;
  animation: Home_showName__gAxUO 0.5s;
}
.Home_eachProject__SKhZa {
  padding: 30px 0;
}

.Home_projectName__2_QNg {
  font-size: 20px;
  padding: 0 0 10px 0;
}

.Home_projectLink__qRfIW,
.Home_card__7oz7W .Home_projectLink__qRfIW {
  font-size: 35px;
  text-decoration: none;
  color: var(--header-color);
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
  opacity: 1;
}

.Home_profileDesc__DTY_P {
  font-family: "Lato", sans-serif;
  font-size: 17px;
}

.Home_footerInfo__6FfZb {
  padding: 15px 0 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Home_contact__7uRuL {
  padding: 15px;
  font-size: 14px;
}

.Home_contact__7uRuL a {
  margin-left: 8px;
}

.Home_projectsGrid__FsKmj {
  display: grid;
  grid-template-columns: repeat(3, minmax(25%, auto));
  width: var(--max-width);
  max-width: 100%;
  animation: Home_showName__gAxUO 0.5s;
}

.Home_card__7oz7W {
  padding: 1rem 1.2rem;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: background 200ms, border 200ms;
  color: var(--foreground-rgb);
}

.Home_card__7oz7W span {
  display: inline-block;
  transition: transform 200ms;
}

.Home_card__7oz7W h2 {
  font-weight: 600;
  margin-bottom: 0.7rem;
}

.Home_card__7oz7W p {
  margin: 0;
  opacity: 0.6;
  font-size: 0.9rem;
  line-height: 1.5;
  max-width: 30ch;
}
.Home_logo__80mSr {
  position: relative;
}
/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .Home_card__7oz7W:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }
}

/* CSS FOR FITNESS PAGE */

.Home_fitnessHeading__I7cKb {
  padding: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  height: calc(100vh - 180px);
}

.Home_fitnessHeaderImgWrapper__Lv8L4 {
  overflow: hidden;
  filter: blur(0px) grayscale(0%);
  animation: Home_fillColor__jGzGP 0.5s;
  animation-timing-function: ease;
  position: relative;
  max-width: 700px;
  width: 100%;
  flex-basis: 50%;
  background: radial-gradient(
    100% 100% at 50% 50%,
    rgba(173, 84, 228, 0.29) 2.55%,
    rgba(173, 84, 228, 0.29) 17.07%,
    rgba(136, 66, 183, 0.29) 24.96%,
    rgba(100, 49, 139, 0.29) 34%,
    rgba(69, 34, 101, 0.241) 43.45%,
    rgba(44, 21, 69, 0.128) 53.21%,
    rgba(25, 12, 45, 0.058) 63.36%,
    rgba(11, 5, 28, 0.02) 70.06%,
    rgba(7, 3, 23, 0.034) 75.69%,
    rgba(3, 1, 18, 0.017) 80.7%,
    rgba(0, 0, 15, 0) 100%
  );
}

@keyframes Home_fillColor__jGzGP {
  from {
    filter: blur(5px);
  }
  to {
    filter: blur(0px);
  }
}

.Home_fitnessHeaderImage__s9Syq {
  filter: drop-shadow(10px 15px 0.5rem #000);
  object-fit: contain;
}

.Home_fitnessHeaderDescWrapper__MjGjw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Home_fitnessHeaderDesc__ga8IT {
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
}

.Home_sectionHeader__fHtmb {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.Home_fitnessPhotosWrapper__HGVm3 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 2rem 0;
}

.Home_fitnessPhotos__0Ausg {
  position: relative;
  width: 15rem;
  height: 20rem;
  margin: 0.5rem;
  /* filter: grayscale(100%); */
  transition: transform 0.2s ease-in;
  border: 1px solid rgb(163, 163, 163);
  border-radius: 0.75rem;
  overflow: hidden;
  cursor: pointer;
  /* aspect-ratio: 1; */
}

.Home_connectWrapper__CAJzC {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
}

.Home_bookContainer__LMJKV,
.Home_collaborateContainer__HQSun {
  flex-basis: 50%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
}

.Home_bookContainer__LMJKV {
  border-right: 1px solid #d4d5d6;
}

.Home_bookText__u46I6 {
  line-height: 1.5rem;
}

.Home_arrowBtnWrapper__vK5T9 {
  position: relative;
}

.Home_arrowSvg__gLYx0 {
  position: absolute;
  height: 50px;
  width: 50px;
  margin: 12px;
  left: 100%;
  top: 0%;
  transform: translateY(-30%) rotate(120deg);
}
.Home_fitnessPhotos__0Ausg:hover {
  transform: scale(1.05);
}

.Home_fitnessPhotoBlur__B6YS_ {
  background: radial-gradient(800px, hsla(0, 0%, 100%, 0.1), transparent 20%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.Home_fitnessPhotoBackground__gbBoy {
  z-index: 0;
  border-radius: 0.75rem;
  filter: blur(24px);
  object-fit: cover;
}

.Home_fitnessPhoto__x05G_ {
  padding: 0.5rem;
  border-radius: 0.75rem;
  z-index: 10;
  object-fit: cover;
}

.Home_linkToBtn__nOQxY {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #0a3477;
  border: 0;
  padding: 12px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  color: var(--foreground-rgb);
}

.Home_linkToBtn__nOQxY:hover {
  box-shadow: 0 0 0.5rem #ffffff70;
}

@media only screen and (max-width: 900px) {
  .Home_menu__dA7XX,
  .Home_headerTools__2QgK2 {
    float: none;
    margin: auto;
    width: max-content;
  }

  .Home_header__qwdnY {
    padding: 0px;
  }
  .Home_container__Ennsq {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .Home_triangle__xTH1a {
    display: none;
  }

  .Home_homepageTitle__gQ1pk {
    padding: 0;
  }

  .Home_profileImage__uGlM_ {
    position: relative;
    width: 50%;
    height: 0;
    padding-bottom: 50%;
    overflow: hidden;
    border: 2px solid aliceblue;
    border-radius: 50%;
    clear: both;
    left: 0;
    top: 150%;
    margin: auto;
    background-image: url(/main_ghibli_image.png);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }

  .Home_profileImage__uGlM_ img {
    display: none;
  }

  .Home_profileName__auQ6_ {
    text-align: center;
    font-size: 3rem;
    line-height: 3rem;
  }

  .Home_profileName__auQ6_ span {
    display: inline;
  }
  .Home_profileDesc__DTY_P {
    text-align: center;
    font-size: 15px;
  }

  .Home_introParagraph__Wut0s {
    padding: 8px;
  }

  .Home_footerInfo__6FfZb {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Home_projectsGrid__FsKmj {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
    max-width: 80vw;
    text-align: center;
    margin: auto;
  }

  .Home_card__7oz7W {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 2.5rem;
  }

  .Home_fitnessHeading__I7cKb {
    height: calc(100vh - 130px);
  }

  .Home_hr__tNQHF {
    width: 100%;
  }
}

/* thai */
@font-face {
  font-family: '__Kanit_4b6796';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1a814473920b47c4-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_4b6796';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ce5a800ee6ddbbac-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_4b6796';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2645a1a789b6057a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_4b6796';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/de69c930925b36eb-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Kanit_Fallback_4b6796';src: local("Arial");ascent-override: 107.66%;descent-override: 38.66%;line-gap-override: 0.00%;size-adjust: 102.17%
}.__className_4b6796 {font-family: '__Kanit_4b6796', '__Kanit_Fallback_4b6796';font-weight: 600;font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__Lato_9e1304';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/162938472036e0a8-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lato_9e1304';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6d664cce900333ee-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Lato_Fallback_9e1304';src: local("Arial");ascent-override: 101.32%;descent-override: 21.87%;line-gap-override: 0.00%;size-adjust: 97.42%
}.__className_9e1304 {font-family: '__Lato_9e1304', '__Lato_Fallback_9e1304';font-weight: 700;font-style: normal
}

